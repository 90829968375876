.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-radius: 15px;
  padding-top: 60px;
  padding-bottom: 20px;
  background-color: rgb(255, 255, 255);
}

.carusel {
  border-radius: 20px;
  width: 1240px;
  overflow: hidden;
}
.slider {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  border-radius: 15px;
  overflow: 'hidden';
  padding: 30px;
  cursor: grab;
  border-radius: 20px;
  object-fit: cover;
}
.name {
  font-size: 16px;
  font-weight: 400;
  align-self: flex-start;
  margin-top: 10px;
}

.post {
  margin-top: 0px;
  font-size: 14px;
  font-weight: 300;
  align-self: flex-start;
  margin-bottom: 10px;
}

.face_pic {
  width: 100%;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
}

.title {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  z-index: 1;
}
.react-multiple-carousel__arrow {
  z-index: 1 !important;
}

@media (max-width: 1250px) {
  .root {
    padding-top: 35px;
    padding-bottom: 20px;
    border-radius: inherit;
  }

  .carusel {
    width: 100%;
  }
  .title {
    margin-bottom: 5px;
  }
}

@media (max-width: 600px) {
  .root {
    padding-top: 35px;
    padding-bottom: 20px;
    border-radius: inherit;
  }

  .carusel {
    width: 100%;
  }
  .title {
    margin-bottom: 5px;
  }
}
