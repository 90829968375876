.menu-enter {
  opacity: 0;
  transition: opacity 300ms;
}

.menu-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

