.root {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: -10px;
  bottom: -43px;
  transform: translate(-50%, -50%);
  z-index: 1200;
}

.sidebar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
}
.sidebar img {
  width: 30px;
  height: 25px;
}

.sidebar_item_home {
  margin-bottom: 20px;
  margin-top: 10px;
  cursor: pointer;
  background-color: #e2e8f0;
  padding: 10px;
  border-radius: 5px;
  z-index: 200;
  cursor: pointer;
}

.sidebar_item_home_rotate {
  margin-bottom: 20px;
  margin-top: 10px;
  cursor: pointer;
  background-color: #e2e8f0;
  padding: 10px;
  border-radius: 5px;
  z-index: 200;
  animation: rotateAnimation 0.5s linear;
}

@keyframes rotateAnimation {
  0% {
    border-radius: 5px;
  }
  50% {
    border-radius: 30%;
    transform: scale(1.2);
  }
  100% {
    border-radius: 5px;
  }
}

.showSidebar {
  transform: translateX(0);
}

.hideSidebar {
  transform: translateX(450%);
}

/* ============================================================= */

.menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: -185px;
  right: 0px;
  border-radius: 8px;
  transition: 0.4s;
  background-color: #ffffff;
  padding: 7px 0px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.menu_item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 130px;
  padding: 7px 10px;
  font-size: 12px;
  color: rgb(89, 89, 89);
  cursor: pointer;
}
.menu_item:hover {
  background-color: #e2e8f0;
}

.line {
  height: 1px;
  width: 100%;
  margin: 7px 0px;
  background-color: rgb(198, 198, 198);
}

.icons {
  color: #f26546;
  font-size: 17px !important;
  margin-right: 7px;
}

@media (max-width: 1350px) {
  .root {
    display: flex;
  }
}
