.alertMobile-enter {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 300ms, transform 300ms;
}

.alertMobile-enter-active {
  opacity: 1;
  transform: translateY(0);
}

.alertMobile-exit {
  opacity: 1;
}
.alertMobile-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
