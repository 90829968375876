@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');

.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.653);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  width: 80%;
  max-width: 590px;
  height: 89%;
  background: #fff;
  overflow: hidden;
  padding: 30px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.content {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  /* border-radius: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px; */
}

.modal img {
  width: 100%;
}

.counter {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fefefe;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #555555a6;
  font-family: 'Play', sans-serif;
}
.arrow {
  color: #fff;
  background-color: #555555a6;
  cursor: pointer;
  width: fit-content;
  margin-right: 10px;
  border-radius: 5px;
  padding: 10px;
  z-index: 200;
  font-family: 'Play', sans-serif;
  font-size: 14px;
  transition: 0.2s;
}
.arrow:hover {
  transition: 0.2s;
  background-color: #343434a6;
}

.arrow_group {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.title {
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  padding: 0px 20px;
}
.desc {
  font-size: 13px;
  font-weight: 400;
  margin-top: 10px;
  padding: 0px 20px;
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  color: #fff;
  border-radius: 5px;
  background-color: #555555a6;
  padding: 3px;
  transition: 0.2s;
}
.close:hover {
  transition: 0.2s;
  background-color: #343434a6;
}

@media (max-width: 750px) {
  .modal {
    width: 94%;
    /* max-width: 590px; */
    /* height: 89%; */
    height: fit-content;
  }
}
