.logo {
  width: 30px;
  margin-top: 17px;

  transition: 0.2s;
}

.root {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vertical-timeline-element-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
