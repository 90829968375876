.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
}

.map_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1150px;
  margin-bottom: 40px;
  position: relative;
}
.map {
  width: 100%;
  z-index: 2;
}
.line_fill {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    135deg,
    rgba(183, 0, 255, 0.2) 25%,
    rgba(0, 247, 255, 0.5) 50%,
    rgba(4, 0, 255, 0.2) 75%
  );
  background-size: 300% 300%;
  animation: gradientMove 3s linear infinite;
}

@keyframes gradientMove {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.mask_map {
  width: 100%;
  position: absolute;
  z-index: 1;
}

@media (max-width: 1250px) {
  .map_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-bottom: 40px;
  }
}
