@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&family=Sofia+Sans+Extra+Condensed:ital,wght@0,1..1000;1,1..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #ffffff;
}
.item_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  border-radius: 20px;
  margin-top: 5px;
  margin-left: 7px;
  cursor: pointer;
}
.left_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-right: 15px;
}

.right_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  height: 75%;
  font-family: 'Sofia Sans Extra Condensed', sans-serif;
  transform: scale(1, 1.2);
  color: #4f4f4f;
}

.desc {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  height: 100%;
  width: 100%;
  color: #757474;
}

