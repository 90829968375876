.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 600px;
  position: relative;
}

.mpk_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 1150px;
}

.block_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.first_block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 520px;
  background-color: white;
  margin-right: 30px;
  padding: 30px;
  border-radius: 20px;
  font-size: 14px;
  color: rgb(64, 64, 64);
  line-height: 22px;
  cursor: default;
}

.second_block {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 210px;
  height: 210px;
  border-radius: 20px;
  background-color: #e2e8f0;
  color: #484848;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  z-index: 10;
  transition: 0.3s;
}

.second_block:hover {
  background-color: #ffffff;
  transition: 0.3s;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.title {
  margin-bottom: 35px;
  font-size: 20px;
  font-weight: 600;
  color: #3b3b3b;
  z-index: 1;
  cursor: default;
}

.icon {
  color: rgb(88, 137, 226);
  align-self: flex-start;
  margin-bottom: 10px;
}

.title_card {
  align-self: flex-start;
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: 600;
  color: #484848;
  font-size: 17px;
}

@media (max-width: 1250px) {
  .root {
    position: relative;
    padding-top: 60px;
    padding-bottom: 600px;
  }
  .block_wrapper {
    flex-direction: column;
    width: 100%;
  }

  .mpk_wrapper {
    width: 100%;
  }

  .first_block {
    width: 60%;
    margin-right: 0px;
  }

  .second_block {
    margin-top: 20px;
    border-radius: 15px;
    width: 60%;
    height: 50px;
    padding: 10px 30px;
  }
  .title {
    margin-bottom: 25px;
  }
}

@media (max-width: 800px) {
  .root {
    position: relative;
    padding-top: 50px;
    padding-bottom: 420px;
  }
  .block_wrapper {
    flex-direction: column;
    width: 100%;
  }

  .mpk_wrapper {
    width: 100%;
  }

  .first_block {
    width: 70%;
    margin-right: 0px;
  }

  .second_block {
    margin-top: 20px;
    border-radius: 15px;
    width: 70%;
    height: 50px;
    padding: 10px 30px;
  }
  .title {
    margin-bottom: 25px;
  }
}

@media (max-width: 600px) {
  .root {
    position: relative;
    padding-top: 40px;
    padding-bottom: 270px;
  }
  .block_wrapper {
    flex-direction: column;
    width: 100%;
  }

  .mpk_wrapper {
    width: 100%;
  }

  .first_block {
    width: 70%;
    margin-right: 0px;
  }

  .second_block {
    margin-top: 20px;
    border-radius: 15px;
    width: 70%;
    height: 50px;
    padding: 10px 30px;
  }
  .title {
    margin-bottom: 25px;
  }
}
