.root {
  position: fixed;
  background-color: white;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  animation: fadeOut 0.3s ease-in-out forwards;
  animation-delay: 1.5s;
  z-index: 1000;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.letter {
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@media (max-width: 800px) {
  .logo {
    transform: scale(0.7);
  }
}

@media (max-width: 600px) {
  .logo {
    transform: scale(0.4);
  }
}
