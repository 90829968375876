.alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.root {
  display: none;
}

@media (max-width: 600px) {
  .root {
    display: inherit;
  }
}
