.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 30px;
  background-color: white;
  padding-bottom: 30px;
}

.passport_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 71%;
  height: 600px;
}

.pic_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 470px;
  position: relative;
}

.passportGirl {
  height: 100%;
}

.first_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #f6f7f8;
  border-radius: 20px;
  min-width: 340px;
  min-height: 320px;
  max-width: 340px;
  max-height: 320px;
  padding: 40px;
  margin-right: 30px;
  margin-left: 30px;
  font-size: 14px;
  color: rgb(64, 64, 64);
  line-height: 22px;
  overflow: hidden;
  cursor: default;
}

.second_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #f6f7f8;
  min-width: 340px;
  min-height: 320px;
  max-width: 340px;
  max-height: 320px;
  padding: 40px;
  border-radius: 20px;
  font-size: 14px;
  color: rgb(64, 64, 64);
  line-height: 22px;
  overflow: hidden;
  cursor: default;
}

.title {
  position: relative;
  align-self: flex-start;
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: 600;
  color: #3b3b3b;
  z-index: 1;
}

.description {
  position: relative;
  z-index: 100;
}

.kioskMask {
  position: absolute;
  width: 40%;
  bottom: -30px;
  right: -35px;
  z-index: 1;
  opacity: 0.2;
}
.passportMask {
  position: absolute;
  width: 40%;
  top: -35px;
  left: -25px;
  z-index: 1;
  opacity: 0.2;
}

.card {
  position: relative;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 180px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.id {
  position: absolute;
  top: 10px;
  right: 10px;
}
.photo {
  position: absolute;
  left: 10px;
  top: 53px;
}
.photo img {
  width: 85px;
  border-radius: 5px;
}
.place {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 10px;
}
.qr {
  position: absolute;
  bottom: 3px;
  right: 10px;
  cursor: pointer;
  transition: 0.2s;
  animation: qrAnimation 3s infinite;
}

@keyframes qrAnimation {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.1);
  }
  20% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(1);
  }
  100% {
    border-radius: 5px;
  }
}

.qr img {
  width: 70px;
}
.titlePass {
  position: absolute;
  top: 10px;
  left: 10px;
  border: 2px solid rgb(109, 150, 228);
  color: rgb(88, 137, 226);
  border-radius: 5px;
  padding: 1px 5px;
  font-weight: 500;
}
.name {
  position: absolute;
  text-align: end;
  right: 10px;
  top: 50px;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
}

.btnMore {
  cursor: pointer;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #484848;
  padding: 0px 30px;
  height: 40px;
  font-weight: 500;
  align-self: flex-start;
  border-radius: 10px;
  transition: 0.3s;
}

.btnMore:hover {
  background-color: #e2e8f0;
  transition: 0.3s;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

a:active,
a:hover,
a {
  text-decoration: none;
  color: #666;
}

.passport_title {
  position: absolute;
  font-size: 20px;
  font-weight: 500;
  top: -65px;
  right: 0px;

  cursor: default;
  color: #3b3b3b;
}

.block_wrapper {
  display: flex;
  position: relative;
}

@media (max-width: 1250px) {
  .passport_title {
    top: -50px;
    right: 50px;
  }
  .root {
    padding-top: 110px;
  }

  .block_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-left: 40px;
  }
  .first_wrapper {
    min-width: inherit;
    min-height: inherit;
    max-width: inherit;
    max-height: inherit;
    margin-right: 0px;
    margin-left: 0px;
    width: 65%;
  }

  .second_wrapper {
    margin-top: 20px;
    min-width: inherit;
    min-height: inherit;
    max-width: inherit;
    max-height: inherit;
    width: 65%;
  }
  .passport_wrapper {
    display: flex;
    height: 100%;
  }
  .block_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .place {
    width: 150px;
    line-height: 12px;
  }
}

@media (max-width: 800px) {
  .root {
    padding-top: 90px;
  }

  .passport_wrapper {
    width: 100%;
    flex-direction: column-reverse;
    height: 100%;
  }

  .block_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-left: 0;
  }

  .first_wrapper {
    min-width: inherit;
    min-height: inherit;
    max-width: inherit;
    max-height: inherit;
    margin-right: 0px;
    margin-left: 0px;
    width: 65%;
  }

  .second_wrapper {
    margin-top: 20px;
    min-width: inherit;
    min-height: inherit;
    max-width: inherit;
    max-height: inherit;
    width: 65%;
  }
  .pic_wrapper {
    margin-top: 20px;
  }
  .passport_title {
    top: -35px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }
}
