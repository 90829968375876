.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgb(13 13 13);
  color: white;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 40px 0px;
}

.column_group {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  width: 65%;
  margin-bottom: 15px;
}

.column {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
}

.column_title {
  font-size: 13px;
  font-weight: 500;
  color: rgb(241, 241, 241);
  margin-bottom: 10px;
}

.column_item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  color: rgb(172, 168, 168);
  margin: 3px 0px;
  font-weight: 400;
  cursor: pointer;
  transition: 0.1s;
}

.column_item:hover {
  transition: 0.1s;
  color: rgb(240, 240, 240);
}
.column_item_doc {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  color: rgb(172, 168, 168);
  margin: 3px 0px 6px 0px;
  font-weight: 400;
  cursor: pointer;
  transition: 0.1s;
}
.column_item_doc:hover {
  transition: 0.1s;
  color: rgb(240, 240, 240);
}
.line {
  width: 65%;
  height: 1px;
  background-color: rgb(73, 73, 73);
  margin: 20px 0px;
}

.logo {
  margin-top: 40px;
  margin-bottom: 20px;
  width: 200px;
}
.desc {
  font-size: 14px;
  margin: 20px 0px;
  color: rgb(119, 119, 119);
  cursor: default;
}

.link img {
  width: 30px;
  padding: 20px 5px;
  cursor: pointer;
}

.phone,
.place,
.email {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  color: rgb(172, 168, 168);
  margin-top: 10px;
  font-weight: 400;
  cursor: default;
}

.time {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  color: rgb(129, 129, 129);
  font-weight: 400;
  cursor: default;
}

.icons {
  color: rgb(230, 230, 230);
  font-size: 25px !important;
  margin-right: 5px;
}

@media (max-width: 1250px) {
  .column_group {
    width: 90%;
  }
  .line {
    width: 90%;
    margin: 10px 0px;
  }
  .link img {
    padding: 10px 5px;
  }
  .desc {
    margin: 10px 0px;
    width: 90%;
    display: flex;
    text-align: center;
  }
  .logo {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

@media (max-width: 850px) {
  .column {
    display: none;
  }
  .column_group {
    width: 80%;
  }
  .line {
    width: 80%;
    margin: 10px 0px;
  }
  .link img {
    padding: 10px 5px;
  }
  .desc {
    margin: 10px 0px;
    width: 90%;
    display: flex;
    text-align: center;
  }
  .logo {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) {
  .column_group {
    width: 80%;
    flex-direction: column;
  }
  .column_doc {
    margin-bottom: 25px;
  }
}
