.root {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 40px;
  transform: translate(-50%, -50%);
  z-index: 200;
}

.sidebar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: transform 0.3s ease-in-out;
}
.sidebar img {
  width: 30px;
  height: 25px;
}

.sidebar_item {
  margin: 10px 0px;
  cursor: pointer;
  background-color: #e2e8f0;
  padding: 10px;
  border-radius: 5px;
  z-index: 200;
}
.sidebar_item_home {
  margin-bottom: 20px;
  margin-top: 10px;
  cursor: pointer;
  background-color: #e2e8f0;
  padding: 10px;
  border-radius: 5px;
  z-index: 200;
}

.showSidebar {
  transform: translateX(0);
}

.hideSidebar {
  transform: translateX(-150%);
}

.sidebar_mask {
  position: absolute;
}
.sidebar_mask img {
  width: 50px;
}

.line {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(231, 231, 231);
  height: 2px;
  width: 75%;
  z-index: 10;
  margin-bottom: 10px;
}

.sidebar_item_double {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  z-index: 120;
}

.title_item {
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 500;
  margin-top: 2px;
}
@media (max-width: 1350px) {
  .root {
    display: none;
  }
}
