.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 30px;
  background-color: white;
  padding-bottom: 50px;
}

/* ----------------------------------------------------------------------------------------- */

.statistic_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 1180px;
}

/* ----------------------------------------------------------------------------------------- */

.left_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 43.8%;
  height: 650px;
  border-radius: 30px;
  position: relative;
}

.left_wrapper img {
  width: 100%;
  height: 650px;
  border-radius: 30px;
}

.left_wrapper_title {
  position: absolute;
  top: 5px;
  left: 10px;
  font-size: 30px;
  font-weight: 400;
  z-index: 10;
}

/* ----------------------------------------------------------------------------------------- */

.right_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 55%;
  height: 650px;
  flex-direction: column;
}

.first {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  background-color: #e2e8f0;
  overflow: hidden;
  border-radius: 30px;
  padding: 25px;
  position: relative;
}

.first_item_title {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: 25px;
  color: rgb(0, 0, 0);
  font-weight: 400;
  z-index: 10;
}

.first_item_group {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  text-align: start;
  font-size: 14px;
}
.first_item {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: start;
  color: rgb(0, 0, 0);
  font-size: 13px;
  width: 48%;
  margin-top: 15px;
  padding: 10px 0px;
  border-radius: 15px;
}
.first_item_icon {
  font-family: 'Sofia Sans Extra Condensed', sans-serif;
  margin-right: 13px;
  color: rgb(0, 0, 0);
  font-size: 45px !important;
}

.people_icon {
  position: absolute;
  right: -25px;
  top: -30px;
  font-size: 135px !important;
  color: rgb(206, 213, 223);
}

.first_item_outside {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: start;
  font-size: 13px;
  color: rgb(0, 0, 0);
  width: 100%;

  border-radius: 15px;
}

/* ----------------------------------------------------------------------------------------- */

.wrapper_info {
  width: 100%;
}

.individual {
  width: 100%;
  background-color: rgb(211, 224, 224);
  font-size: 13px;
  color: black;
  padding: 20px 30px;
  box-sizing: border-box;
  border-radius: 20px;
  position: relative;
  display: flex;
  align-items: center;
}

.info_icon {
  position: absolute;
  right: 30px;
  font-size: 30px !important;
  color: black;
  opacity: 0.2;
}

.second {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;

  /* margin-top: 90px; */
}

.second_title {
  font-size: 13px;
  text-align: start;
  width: 100%;
  /* background-color: rgb(211, 224, 224); */
  background-color: rgb(73, 73, 73);
  display: flex;
  color: #e2e8f0;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 25px 30px 0px 30px;
  border-radius: 30px 30px 0px 0px;
}

.second_block_left {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  height: 100%;
  background-color: rgb(73, 73, 73);
  border-radius: 0px 0px 30px 30px;
}

.second_block_left_item {
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
  font-size: 13px;
}
.count {
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 35px;
  width: 40px;
  margin-right: 30px;
  margin-left: 10px;
  font-family: 'Sofia Sans Extra Condensed', sans-serif;
  font-weight: 400;
}

.desc {
}

.second_block_right {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
  flex-direction: column;
  width: 39%;
  height: 100%;
  background-color: gainsboro;
  border-radius: 30px;
  padding: 20px;
}

.second_block_right_item {
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  display: flex;
  color: white;
  font-size: 13px;
  color: black;
}

.second_block_right_group {
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  display: flex;
  width: 100%;
}
.count2 {
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 35px;
  width: 40px;
  margin-right: 30px;
  margin-left: 10px;
  font-family: 'Sofia Sans Extra Condensed', sans-serif;
  font-weight: 400;
}

.desc2 {
}

/* ----------------------------------------------------------------------------------------- */

.third {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100px;
  position: relative;
}

.third_block_left {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 110px;

  border-radius: 50%;
  position: absolute;
  left: 0px;
  z-index: 10;
}
.third_block_left img {
  width: 100%;
  border-radius: 50%;
  /* border: 10px solid rgb(230, 229, 227); */
  filter: brightness(130%) contrast(90%);
}

.third_block_right {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 84%;
  height: 100%;
  font-size: 13px;
  background-color: rgb(230, 229, 227);
  border-radius: 50px;
  padding: 0px 80px 0px 40px;
  position: relative;
}
.formatQuoteIcon {
  position: absolute;
  right: 20px;
  bottom: 10px;
  font-size: 70px !important;
  color: rgb(180, 180, 180);
}

@media (max-width: 1250px) {
  .root {
    width: 100%;
  }
  .statistic_wrapper {
    width: 95%;
    flex-direction: column;
  }
  .left_wrapper {
    display: none;
  }
  .left_wrapper_title {
    display: none;
  }
  .right_wrapper {
    width: 80%;
  }
  .second_title {
    width: 100%;
  }
  .third_block_right {
    width: 90%;
    padding: 20px 90px 20px 60px;
  }
}

@media (max-width: 840px) {
  .left_wrapper {
    display: flex;
    width: 80%;
    height: fit-content;
    margin-bottom: 15px;
  }

  .left_wrapper_title {
    display: flex;
  }

  .left_wrapper img {
    width: 100%;
    height: 100%;
    border-radius: 30px;
  }
  .second {
    flex-direction: column;
  }
  .right_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    height: inherit;
    flex-direction: column;
  }
  .second_block_left {
    margin-bottom: 15px;
    width: 100%;
  }
  .second_block_right {
    width: 100%;
  }

  .first {
    margin-bottom: 15px;
  }

  .third {
    margin-top: 15px;
  }
}

@media (max-width: 840px) {
  .left_wrapper_title {
    font-size: 22px;
  }
  .desc2 {
    margin-bottom: 20px;
  }
}
@media (max-width: 730px) {
  .individual {
    box-sizing: border-box;
    padding-right: 90px;
  }
  .root {
    padding-bottom: 50px;
    padding-top: 10px;
  }
  .third {
    height: inherit;
  }
  .third_block_left {
    display: none;
  }
  .third_block_right {
    width: 100%;
    border-radius: 30px;
    padding: 20px 90px 20px 30px;
  }
}

@media (max-width: 500px) {
  .left_wrapper_title {
    font-size: 18px;
  }
}
@media (max-width: 430px) {
  .right_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: inherit;
    flex-direction: column;
  }
  .left_wrapper {
    display: flex;
    width: 90%;
    height: fit-content;
    margin-bottom: 15px;
  }
  .left_wrapper_title {
    font-size: 18px;
  }
}
