@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Tektur:wght@400;500;600;700&display=swap');


.root {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -30px;
}

.map {
  position: relative;
  margin-bottom: 100px;
  margin-top: 30px;
}
/* ---------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------- */

.clear_map {
  width: 800px;
  /* position: absolute; */
}
.clear_map img {
  width: 800px;
}

/* ---------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------- */

.sklad {
  width: 140px;
  position: absolute;
  left: 2.2%;
  bottom: 38%;
  cursor: pointer;
  transition: 0.1s;
  z-index: 10;
}
.sklad img {
  width: 140px;
  transition: 0.1s;
}

.sklad:hover {
  width: 140px;
  position: absolute;
  left: 1.3%;
  bottom: 37.5%;
  cursor: pointer;
  transition: 0.1s;
}

.sklad:hover img {
  width: 160px;
  transition: 0.1s;
}

.skladShadow {
  opacity: 0;
  width: 200px;
  position: absolute;
  left: -1.5%;
  bottom: 33.65%;
  cursor: pointer;
  transition: 0.1s;
}
.skladShadow img {
  width: 200px;
}
.sklad:hover ~ .skladShadow {
  opacity: 1;
  transition: 0.1s;
}

/* ---------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------- */

.factory {
  width: 140px;
  position: absolute;
  right: 39.5%;
  bottom: 4%;
  cursor: pointer;
  transition: 0.1s;
  z-index: 10;
}
.factory img {
  width: 140px;
  transition: 0.1s;
}

.factory:hover {
  width: 140px;
  position: absolute;
  right: 40.5%;
  bottom: 4%;
  cursor: pointer;
  transition: 0.1s;
}

.factory:hover img {
  width: 160px;
  transition: 0.1s;
}

.factoryShadow {
  opacity: 0;
  width: 200px;
  position: absolute;
  right: 34.35%;
  bottom: 4.7%;
  cursor: pointer;
  transition: 0.1s;
}
.factoryShadow img {
  width: 165px;
}
.factory:hover ~ .factoryShadow {
  opacity: 1;
  transition: 0.1s;
}

/* ---------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------- */

.city {
  width: 140px;
  position: absolute;
  right: 47.4%;
  top: 0.4%;
  cursor: pointer;
  transition: 0.1s;
  z-index: 10;
}

.city img {
  width: 240px;
  transition: 0.1s;
}

.city:hover {
  width: 140px;
  position: absolute;
  right: 48.2%;
  top: -1%;
  cursor: pointer;
  transition: 0.1s;
}

.city:hover img {
  width: 253px;
  transition: 0.1s;
}

.cityShadow {
  opacity: 0;
  width: 200px;
  position: absolute;
  right: 44.85%;
  top: -4.7%;
  cursor: pointer;
  transition: 0.1s;
}
.cityShadow img {
  width: 315px;
}
.city:hover ~ .cityShadow {
  opacity: 1;
  transition: 0.1s;
}

/* ---------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------- */

.grayscale {
  filter: grayscale(100%);
  transition: 0.1s;
}

/* ---------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------- */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.infoCity {
  top: 20%;
  right: 48.3%;
  position: absolute;
  z-index: 20;
  animation: spin 7s linear infinite;
  cursor: pointer;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 140, 0, 0.596);
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.infoCity img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}

/* ---------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------- */

.infoFactory {
  bottom: 9.5%;
  right: 47%;
  position: absolute;
  z-index: 30;
  animation: spin 7s linear infinite;
  cursor: pointer;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 140, 0, 0.596);
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
.infoFactory img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}

/* ---------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------- */

.infoSklad {
  bottom: 43%;
  left: 8%;
  position: absolute;
  z-index: 20;
  animation: spin 7s linear infinite;
  cursor: pointer;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 140, 0, 0.596);
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
.infoSklad img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}

/* ---------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------- */

.dialogCity {
  position: absolute;
  z-index: 20;
  top: 5%;
  right: 5%;
  transition: 0.4s;
}
.dialogCity img {
  width: 210px;
  transition: 0.4s;
}
.dialogySklad {
  position: absolute;
  z-index: 20;
  bottom: 65%;
  left: -8%;
  transition: 0.4s;
}
.dialogySklad img {
  width: 210px;
  transition: 0.4s;
}
.dialogFactory {
  position: absolute;
  z-index: 20;
  bottom: -4%;
  right: 6%;
  transition: 0.4s;
}
.dialogFactory img {
  width: 210px;
  transition: 0.4s;
}

/* ---------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------- */

.rotate {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 12;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.685);
  width: 170px;
  height: 170px;
  border-radius: 50%;
}
.rotate::before {
  content: ''; /* добавлен пустой контент */
  position: absolute;
  z-index: -1; /* изменено на -1, чтобы псевдоэлемент был "под" основным контентом */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.438);
  border-radius: inherit;
  filter: blur(10px);
}

.rotatePhone {
  position: absolute;
  width: 55px !important;
  border-radius: 0% !important;
  animation: rotatePhone 1s infinite alternate;
}

.rotateArrow {
  position: absolute;
  width: 150px !important;
  border-radius: 0% !important;
  animation: rotateArrow 5s infinite;
}

@keyframes rotateArrow {
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotatePhone {
  to {
    transform: rotate(30deg);
  }
}

/* ---------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------- */
.title {
  position: absolute;
  top: 17px;
  left: 58px;
  font-family: 'Tektur', sans-serif;
  font-weight: 500;
  font-size: 13px;
}

.desc1 {
  position: absolute;
  top: 32px;
  left: 20px;
  font-family: 'Tektur', sans-serif;
  font-weight: 500;
  font-size: 10px;
}
.desc2 {
  position: absolute;
  top: 43px;
  left: 20px;
  font-family: 'Tektur', sans-serif;
  font-weight: 500;
  font-size: 10px;
}
.desc3 {
  position: absolute;
  top: 55px;
  left: 20px;
  font-family: 'Tektur', sans-serif;
  font-weight: 500;
  font-size: 10px;
}

.close {
  position: absolute;
  top: -2px;
  left: 23px;
  font-size: 16px !important;
  cursor: pointer;
}

.alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

@media (max-width: 1250px) {
  .root {
    transform: scale(90%);
    position: absolute;
  }
}
@media (max-width: 800px) {
  .root {
    transform: scale(70%);
    position: absolute;
    bottom: -120px;
  }
}

@media (max-width: 600px) {
  .root {
    transform: scale(50%);
    position: absolute;
    bottom: -180px;
    pointer-events: none;
  }
  .rotate {
    display: flex;
    pointer-events: all;
  }
}
@media (max-width: 400px) {
  .root {
    transform: scale(30%);
    position: absolute;
    pointer-events: none;
  }
}
