.messageAnimation-enter {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.messageAnimation-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease; 
}

.messageAnimation-exit {
  opacity: 1;
  transition: opacity 0.5s ease; 
}

.messageAnimation-exit-active {
  opacity: 0;
  transition: opacity 0.5s ease; 
}
