@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-top: 100px;
  background-color: #f6f7f8;
  padding-bottom: 100px;
}

.education_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 71%;
}

.info_wrapper {
}
.text_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.column {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 190px;
  height: 320px;
  background-color: white;
  margin-right: 30px;
  border-radius: 15px;
  padding: 30px;
  font-size: 13px;
  color: rgb(92, 91, 91);
  line-height: 20px;
  transition: 0.5s;
  cursor: default;
}

.title {
  align-self: flex-start;
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: 600;
  color: #3b3b3b;
}

.graphic_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.btn_more {
  position: absolute;
  cursor: pointer;
  bottom: -55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e2e8f0;
  color: #484848;
  font-weight: 500;
  height: 40px;
  width: 190px;
  padding: 0px 30px;
  border-radius: 10px;
  font-size: 14px;
  transition: 0.4s;
}
.btn_more:hover {
  background-color: #ffffff;
  transition: 0.4s;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.pic_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 470px;
  position: relative;
}

.educationGuy {
  height: 100%;
}

.message {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}

.alertMessage {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  animation: sway 1s infinite;
}

@keyframes sway {
  0% {
    transform: rotateZ(1.5deg);
  }
  50% {
    transform: rotateZ(-1.5deg);
  }
  100% {
    transform: rotateZ(1.5deg);
  }
}

.textMessage {
  position: absolute;
  left: 25px;
  top: 172px;
  font-size: 11px;
  line-height: 14px;
  cursor: default;
}

.icons {
  color: rgb(88, 137, 226);
  align-self: flex-start;
  margin-bottom: 10px;
}

.education_title {
  display: flex;
  position: absolute;
  font-size: 20px;
  font-weight: 500;
  top: -55px;
  left: 0;
  color: #000000;
  width: 400px;
  color: #3b3b3b;
}
.education_title_mobile {
  display: none;
  position: absolute;
  font-size: 20px;
  font-weight: 500;
  top: -60px;
  left: 0;
  color: #000000;
  color: #3b3b3b;
}

.link {
  position: absolute;
  color: purple;
  font-size: 16px;
  border-radius: 5px;
  bottom: -20px;
}
.link:hover {
  color: rgb(24, 20, 247);
}

@media (max-width: 1250px) {
  .root {
    padding-top: 50px;
    padding-bottom: 110px;
  }
  .education_title {
    font-weight: 500;
    top: -45px;
  }
  .text_wrapper {
    flex-direction: column;
  }
  .column {
    margin-bottom: 20px;
    margin-right: 0px;
    width: 65%;
    height: 100%;
  }
  .pic_wrapper {
    height: 490px;
  }
  .textMessage {
    top: 182px;
  }
  .btn_more {
    width: 100%;
    margin-top: 0px;
    padding: 0px 0px;
  }
  .column {
    margin-top: 60px;
  }
}
@media (max-width: 800px) {
  .root {
    padding-top: 30px;
    padding-bottom: 40px;
  }

  .education_wrapper {
    flex-direction: column;
    width: 100%;
    position: relative;
  }

  .text_wrapper {
    flex-direction: column;
  }

  .column {
    margin-bottom: 20px;
    margin-right: 0px;
    width: 65%;
    height: 100%;
  }
  .graphic_wrapper {
    margin-top: 0px;
  }

  .pic_wrapper {
    height: 450px;
    margin-top: 30px;
  }

  .educationGuy {
    height: 100%;
  }

  .textMessage {
    top: 162px;
    font-size: 11px;
  }
  .graphic_wrapper {
    width: 100%;
    justify-content: center;
  }
  .btn_more {
    margin-top: 0px;
  }
  .education_title {
    top: -50px;
    display: none;
  }
}

@media (max-width: 800px) {
  .education_title_mobile {
    display: block;
    top: -35px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }
  .education_title {
    top: -50px;
    display: none;
  }
  .pic_wrapper {
    margin-top: 60px;
  }
  .education_title_mobile {
    top: 25px;
  }
}
