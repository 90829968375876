.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.banner_wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  height: 100%;
}

.banner_wrapper img {
  width: 100%;
}

.logos {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5%;
  top: 10%;
  left: 2%;
  width: 67%;
  height: 5%;
}

.logos img {
  cursor: pointer;
  height: 10%;
}

.line {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 3px;
  height: 100%;
  background-color: white;
}

.link {
  width: fit-content;
}

/* ------------------------------------------ */

.logosMobile {
  position: absolute;
  display: none;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 40px;
  top: 4%;
  left: 9%;
  height: 100%;
  width: fit-content;
}

.logosMobile img {
  cursor: pointer;
  height: 60px;
  width: fit-content;
  max-width: 306px;
}
.simax {
  width: 306px;
}
.yuc {
  width: 187px;
}
.dpo {
  width: 302px;
}

@media (max-width: 700px) {
  .logos {
    display: none;
  }
  .logosMobile {
    display: flex;
  }
}

@media (max-width: 600px) {
  .logosMobile {
    position: absolute;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 35px;
    top: 3%;
    left: 5%;
  }

  .logosMobile img {
    cursor: pointer;
    height: 60px;
    width: fit-content;
  }

  .linkMobile {
    height: fit-content;
    width: fit-content;
  }
}
@media (max-width: 550px) {
  .logosMobile {
    position: absolute;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 30px;
    top: 3%;
    left: 5%;
  }

  .logosMobile img {
    cursor: pointer;
    height: 50px;
    width: fit-content;
    max-width: 225px;
  }

  .simax {
    width: 225px !important;
  }
  .dpo {
    width: 225px !important;
  }
  .yuc {
    width: 155px !important;
  }
}
@media (max-width: 480px) {
  .logosMobile {
    position: absolute;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 30px;
    top: 3%;
    left: 5%;
    height: 100%;
    width: fit-content;
  }

  .logosMobile img {
    cursor: pointer;
    height: 45px;
    width: fit-content;
  }
  .simax {
    width: 225px !important;
  }
  .dpo {
    width: 225px !important;
  }
  .yuc {
    width: 145px !important;
  }
}
@media (max-width: 440px) {
  .logosMobile {
    position: absolute;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 30px;
    top: 3%;
    left: 5%;
    height: 100%;
    width: fit-content;
  }

  .logosMobile img {
    cursor: pointer;
    height: 40px;
    width: fit-content;
    max-width: 204px;
  }
  .simax {
    width: 204px !important;
  }
  .dpo {
    width: 201px !important;
  }
  .yuc {
    width: 124px !important;
  }
}
@media (max-width: 380px) {
  .logosMobile {
    position: absolute;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 30px;
    top: 3%;
    left: 5%;
    height: 100%;
    width: fit-content;
  }

  .logosMobile img {
    cursor: pointer;
    height: 35px;
    width: fit-content;
    max-width: 178px;
  }
  .simax {
    width: 178px !important ;
  }
  .dpo {
    width: 176px !important;
  }
  .yuc {
    width: 109px !important;
  }
}
